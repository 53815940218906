<template lang="pug">
TheBook(
	:title="workers.title"
	:endpoint="workers.endpoint"
	:path="workers.path"
	:left="workers.left"
	:leftSub="workers.leftSub"
	needSearch
)
</template>

<script>
import TheBook from '../components/TheBook'
import BOOKS from '../modules/constants/books'

export default {
	name: 'Workers',
	components: { TheBook },
	setup () {
		const { workers } = BOOKS

		return {
			workers
		}
	}
}
</script>
